<template>
  <b-card
    no-body
    class="pmp-ad-collapse"
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <div class="d-flex flex-row justify-content-between w-100">
          <span
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.left="title"
            class="d-block title-class text-truncate text-primary"
          >
            {{ title }}
          </span>
          <div class="d-flex flex-row justify-content-between w-65">
            <div>
              <div
                v-if="spinner[id]"
                style="width: 75px;"
                class="spinner text-center"
              >
                <b-spinner
                  variant="primary"
                  small
                />
              </div>
              <span
                class="time"
              >
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  class="mr-1"
                />
                {{ dateFromISOFomat(pmpAd.start_date) }} to {{ dateFromISOFomat(pmpAd.end_date) }}
              </span>
            </div>
            <span
              v-if="pmpAd.status === PmpAdsStatusType.PENDING && isEditDisabled"
              class="no-wrap border"
            >
              <b-button
                :id="'approve-button' + id"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-success"
                size="sm"
                class="btn-icon rounded-circle"
                @click="(event) => { event.stopPropagation(); approvePmpAd(id,title)}"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="16"
                />
              </b-button>
              <b-tooltip
                :target="'approve-button'+ id"
              >
                <span>Approve</span>
              </b-tooltip>
              <b-button
                :id="'reject-button' + id"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                size="sm"
                class="btn-icon rounded-circle m-0"
                @click="(event) => { event.stopPropagation(); rejectPmpAd(id,title)}"
              >
                <feather-icon
                  size="16"
                  icon="XIcon"
                />
              </b-button>
              <b-tooltip
                :target="'reject-button'+ id"
              >
                <span>Reject</span>
              </b-tooltip>
            </span>
            <div class=" d-flex flex-row align-items-center mt-1 mt-md-0 mt-sm-0 mt-xs-0 noMarginTOp">
              <div>
                <b-badge
                  v-if="pmpAd.status !== PmpAdsStatusType.PENDING"
                  pill
                  variant="danger"
                  class="badge-glow textClass"
                >Non Editable</b-badge>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-1 mx-2 mt-md-0 mt-sm-0 mt-xs-0 noMarginTOp">
                <span
                  v-if="pmpAd.status === PmpAdsStatusType.APPROVED"
                  class="font-weight-bold"
                >
                  <span
                    class="font-small-3 approval-tag-class "
                  >Approved</span>
                </span>
                <span
                  v-if="pmpAd.status === PmpAdsStatusType.PENDING"
                  class=" font-weight-bold"
                >
                  <span>
                    <span
                      class="font-small-3 pending-tag-class"
                    >Pending</span>
                  </span>
                </span>
                <span
                  v-if="pmpAd.status === PmpAdsStatusType.REJECTED"
                  class=" font-weight-bold"
                >
                  <span>
                    <span
                      class="font-small-3 rejected-tag-class"
                    >Rejected</span>
                  </span>
                </span>
              </div>
              <div class="DivControls">
                <feather-icon
                  :id="id"
                  icon="EyeIcon"
                  class="cursor-pointer"
                  size="16"
                  style="margin: 8px"
                  @click="reviewPmpAd($event)"
                />
                <b-tooltip
                  v-if="pmpAd.status === PmpAdsStatusType.REJECTED"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span>Rejected External Ads cannot be edited!</span>
                </b-tooltip>
                <b-tooltip
                  v-if="pmpAd.status === PmpAdsStatusType.APPROVED"
                  :target="'tooltip-icon-disable' + id"
                >
                  <span>Approved External Ad cannot be edited! </span>
                </b-tooltip>
                <span
                  v-if="!isEditDisabled"
                  :id="'tooltip-icon-disable' + id"
                >
                  <feather-icon
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    :class="pmpAd.status !== PmpAdsStatusType.PENDING ? 'disableIcon':'null'"
                    size="16"
                    style="margin: 8px; cursor: pointer"
                    @click="editFunc($event, pmpAd.cancelled_by)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </b-card-header>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0 !important">
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  VBPopover,
  BSpinner,
  VBToggle,
  VBTooltip,
  BTooltip,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import moment from 'moment'
import TimeLimitCondition from '@/common/config'
import PmpAdsStatusType from '@/common/enums/PmpAdsStatusEnum'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showErrorMessage, showToast } from '@/common/global/functions'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
    BSpinner,
    BTooltip,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  filters: {
    moment(date) {
      return moment.utc(date).format('DD-MM-YYYY, HH:mm')
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    pmpAd: {
      type: Object,
      required: true,
    },
    isEditDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      TimeLimitCondition,
      allowTimeForApproval: false,
      currentTime: null,
      spinner: {},
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      lat: '',
      lng: '',
      locationAddress: '',
      // validation
      required,
      PmpAdsStatusType,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  mounted() {
  },
  methods: {
    async approvePmpAd(id, name) {
      const result = await this.openSwal('question', name, 'Approve')
      if (result) {
        try {
          await this.$store.dispatch('pmpAds/approvePmpAdByAdmin', { id })
          this.successSwal('Approved')
          this.$emit('refreshList')
        } catch ({
          response: {
            data: { statusCode, message },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('External Ads', 'Some thing went wrong!', 'danger')
            this.pmpAdsLoading = false
          }
        }
      }
    },
    async rejectPmpAd(id, name) {
      const result = await this.openSwal('question', name, 'Reject')
      if (result) {
        try {
          await this.$store.dispatch('pmpAds/rejectPmpAdByAdmin', { id })
          this.successSwal('Rejected')
          this.$emit('refreshList')
        } catch ({
          response: {
            data: { statusCode, message },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('External Ads', 'Some thing went wrong!', 'danger')
            this.pmpAdsLoading = false
          }
        }
      }
    },
    successSwal(action) {
      this.$swal.fire({
        position: 'center',
        icon: 'success',
        title: `${action} Successfully`,
        showConfirmButton: false,
        timer: 1000,
      })
    },
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
      if (val) {
        this.$emit('getImpressions', this.id)
      }
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },

    editFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      //   if (!(this.approved !== null) && !cancelled) {
      if (this.pmpAd.status === PmpAdsStatusType.PENDING) {
        this.$emit('editPmpAds', { id: this.id })
      }
    //   }
    },
    switchFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.switchs = !this.switchs
    },
    dateFromISOFomat(date) {
      const dateTime = new Date(date)
      return dateTime.toISOString().split('T')[0]
    },
    reviewPmpAd(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.isUserAdmin && this.isEditDisabled === true) {
        this.$router.push(`/pmpAdsList/view-ads/${this.id}`)
      } else if (this.isUserAdmin) {
        this.$router.push(`/users/${this.$route.params.userId}/user-pmp-ads/view-ads/${this.id}`)
      } else {
        this.$router.push(`/pmp-ads/view/${this.id}`)
      }
    },
    async openSwal(icon, name, action) {
      const value = await this.$swal({
        title: `<span class="font-weight-bolder">${action} External Ad?</span>`,
        icon,
        html: `You want to ${action.toLowerCase()} ${name}`,
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: action,
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            return true
          }
          return false
        })
      return value
    },
  },
}
</script>
  <style lang="scss">
  .pmp-ad-collapse {
    .switches {
      border-radius: 25px;
    }

    .device {
      font-size: 14px;
      letter-spacing: 0px;
      color: #414141;
      font-weight: 600;
      font-family: "Montserrat";
      padding-left: 15px;
    }

  .time {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 21px;
    color: #414141;
    font-weight: 500;
    font-family: "Montserrat";
    margin-top: 4px;
    min-width: 240px;
  }

    .name-class {
      width: 300px !important;
      @media (max-width: 1000px) {
        //min-width: 200px;
        width: 200px !important;
        font-size: 14px;
      }
    }

    .title-class {
      font-size: 16px;
      letter-spacing: 0px;
      font-weight: 600;
      font-family: "Montserrat";
      max-width: 270px !important;
      min-width: 270px
    }
    .disableIcon {
      color: #80808080;
    }
    .location-class {
      font-size: 14px;
      letter-spacing: 0px;
      color: #414141;
      font-weight: 500;
      font-family: "Montserrat";
    }
    @media (max-width: 880px) {
      .title-class {
        font-size: 14px !important;
        max-width: 150px !important;
        min-width: 150px !important;
      }
      .time {
        font-size: 12px;
        min-width: 120px;
        max-width: 120px;
      }
    }
    @media (max-width: 650px) {
      .title-class {
        font-size: 12px !important;
        max-width: 80px !important;
        min-width: 80px !important;
      }
      .time {
        display: none;
        font-size: 2px;
        min-width: 8px;
        max-width: 8px;
      }
      .approval-tag-class {
        padding: 4px 6px;
        font-size: 8px !important;
      }
      .rejected-tag-class {
        padding: 6px 6px;
        font-size: 8px !important;
      }
      .pending-tag-class {
        padding: 6px 6px;
        font-size: 8px !important;
      }
      .textClass{
        font-size: 8px;
      }
    .noMarginTOp{
      margin-top: 0 !important;
    }
    .DivControls{
      display: flex;
    }
    }
  }
  </style>
